.Conclusion {
    margin-top: 1em;
    text-align: center;
    display: grid;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;
}

.Pass-patient-text {
    font-size: 32px;
}

.Pass-text {
    margin-top: -15px;
    font-size: 100px;
    font-weight: 800;
    text-align: center;
}

.Pass-followup-text {
    font-size: 24px;
    margin-top: 1em;
    margin-bottom: -30px;
}

.Alert-followup-text {
    font-size: 24px;
    margin-top: 1em;
    margin-bottom: -30px;
}