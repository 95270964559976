.Content-body {
    font-size: 16px;
    color: #000;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.Content-body-centred {
    font-size: 18px;
    color: #000;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.Content-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 1em;
    width: 100%;
}

.Content-smaller-text {
    text-align: center;
    max-width: 500px;
    width: 90%;
    margin-bottom: 1em;
    display: flex;
}

.Content-title {
    font-size: 24px;
    font-weight: 900;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
}

.Content-subtitle {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.25em 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    margin-bottom: 0.5em;
}

.Content-subheading {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 1rem;
}

.Content-disclaimer {
    font-size: 16px;
    font-weight: 700;
}

.Content-disclaimer-list {
    text-align: left;
    font-size: 16px;
    margin-left: -1em;
    line-height: 1.5rem;
    padding-bottom: 0.5rem;
}

.Content-question {
    font-size: 28px;
}

.Content-solo-question {
    margin-top: 6em;
    text-align: center;
}

.Privacy-desc {
    padding-top: 2em;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.Default-link {
    color: #0000ee;
    cursor: pointer;
    text-decoration: underline;
}

.Privacy-footer {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
