.Symptom-list {
    font-size: 22px;
    max-width: 90%;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: -0.25em;
}
  
.Symptom-list > li {
    max-width: 90%;
    padding: 0.25em 0;
}