.Dots {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-items: center;
}

.Unselected-dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
}

.Selected-dot {
    height: 10px;
    width: 10px;
    background-color: rgb(0, 162, 255);
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
}