.Container-none {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Container-pass {
    min-height: 100vh;
    background-color: rgb(0, 162, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Container-fail {
    min-height: 100vh;
    background-color: rgb(241, 114, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Container-alert {
    min-height: 100vh;
    background-color: rgb(255,195,8);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Container-main {
    max-width: 500px;
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Container-logo {
    width: 80%;
    max-width: 250px;
    pointer-events: none;
}
  
.Container-header {
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}