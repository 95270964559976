

.Button {
    background: rgb(200,200,200);
    color: rgb(0, 118, 255);
    font-size: 18px;
    text-align: center;
    width: 50%;
    padding: 0.5em;
    cursor: pointer;
}

.Button:hover {
    font-weight: 600;
}

.Button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: 1px solid rgb(200,200,200); 
    background: linear-gradient(135deg, rgba(200,200,200,1) 0%, rgba(225,225,225,1) 100%); 
}

.Button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: 1px solid rgb(200,200,200); 
    background: linear-gradient(135deg,  rgba(225,225,225,1) 0%, rgba(200,200,200,1) 100%); 
}

.Button:only-child {
    background: linear-gradient(90deg,  rgba(225,225,225,1) 0%, rgba(200,200,200,1) 100%); 
}

.Button-group {
    margin-top: 0.5em;
    max-width: 500px;
    width: 90%;
    display: flex;
}